.parallaxLargeTextContainer {
  margin-bottom: var(--size-twenty);
}

input[type="button"] {
  margin-top: calc(var(--size-ten) + 1vw);
  padding: var(--size-two) var(--size-ten);
  font-size: calc(var(--size-five) + 1vw);
  width: auto;
} 

.topLeftBoxOne,
.topLeftBoxTwo,
.bottomRightBoxOne,
.bottomRightBoxTwo {
  position: absolute;
  border: var(--size-two) solid transparent;
  border-radius: var(--size-ten);
  width: calc(var(--size-fifty) + 2vw);
  height: calc(var(--size-fifty) + 2vw);
  z-index: -1;
}

.topLeftBoxOne,
.topLeftBoxTwo {
  top: 0;
  left: 0;
}

.topLeftBoxOne {
  transform: translate(-25%, -25%);
}

.topLeftBoxTwo {
  transform: translate(-50%, -50%);
}

.bottomRightBoxOne,
.bottomRightBoxTwo {
  bottom: 0;
  right: 0;
}

.bottomRightBoxOne {
  transform: translate(25%, 25%);
}

.bottomRightBoxTwo {
  transform: translate(50%, 50%);
}