.scrollContainerWidth {
  width: 80%;
  max-width: 1200px;
}

.contactContainer .formFlexContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.outerFlexOne {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.innerFlexOne,
.innerFlexTwo {
  width: 100%;
}

.innerFlexOne {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-right: calc(var(--size-ten) + 1vw);
  height: 100%;
}

.innerFlexTwo {
  margin-left: calc(var(--size-ten) + 1vw);
}

.innerFlexTwo textarea {
  height: calc(var(--size-fifty) + 5vw);
  border-top-right-radius: var(--size-five);
  border-bottom-right-radius: var(--size-five);
}

.outerFlexTwo {
  margin-top: calc(var(--size-twenty) + 1vw);
}

.outerFlexTwo input[type="submit"] {
  padding: calc(var(--size-two) + 1vw) calc(var(--size-twenty) + 1vw);
  font-weight: 700;
  font-size: calc(var(--size-ten) + 1vw);
  font-family: 'Sen', 'Barlow', 'Exo 2', sans-serif;
}

.bottomLeftBoxOne,
.bottomLeftBoxTwo,
.topRightBoxOne,
.topRightBoxTwo {
  position: absolute;
  border: var(--size-two) solid transparent;
  border-radius: var(--size-ten);
  width: calc(var(--size-fifty) + 2vw);
  height: calc(var(--size-fifty) + 2vw);
  z-index: -1;
}

.bottomLeftBoxOne,
.bottomLeftBoxTwo {
  bottom: 0;
  left: 0;
}

.bottomLeftBoxOne {
  transform: translate(-25%, 25%);
}

.bottomLeftBoxTwo {
  transform: translate(-50%, 50%);
}

.topRightBoxOne,
.topRightBoxTwo {
  top: 0;
  right: 0;
}

.topRightBoxOne {
  transform: translate(25%, -25%);
}

.topRightBoxTwo {
  transform: translate(50%, -50%);
}

.errorMessage {
  font-size: calc(7px + 0.5vw);
}

@media screen and (max-width: 600px) {
  .outerFlexOne {
    flex-wrap: wrap;
  }

  .innerFlexTwo,
  .innerFlexOne {
    margin: 0;
  }

  .innerFlexOne {
    height: auto;
  }

  .innerFlexTwo textarea {
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--size-five);
  }
}