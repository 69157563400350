* {
  --size-one: 0.0625rem;
  --size-two: 0.125rem;
  --size-five: 0.3125rem;
  --size-ten: 0.625rem;
  --size-fifteen: 0.9375rem;
  --size-twenty: 1.25rem;
  --size-thirty: 1.875rem;
  --size-forty: 2.5rem;
  --size-fifty: 3.125rem;
  --size-one-hundred: 6.25rem;
  --size-three-hundred: 18.75rem;
  --transition-one: ease-in-out 0.25s;
  --transition-two: ease-in-out 0.5s;
  --transition-three: ease-in-out 1s;
  --text-shadow-one: 0 var(--size-one) var(--size-two) grey;
  --input-color: #36454F;
}

body {
  margin: 0;
  font-family: 'Exo 2', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: calc(0.75rem + 1vw);
  min-width: var(--size-three-hundred);
  transition: var(--transition-one);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, a, p, div {
  transition: var(--transition-one);
  margin: 0;
}

a {
  text-decoration: none;
  color: aqua;
}

input,
textarea {
  box-sizing: border-box;
  font-family: 'Exo 2', 'Barlow', sans-serif;
  font-weight: 400; 
  width: 100%;
}

input {
  font-size: calc(var(--size-ten) + 1vw);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid;
}

textarea {
  border: none;
  resize: none;
  font-size: calc(var(--size-five) + 1vw);
}

input[type="button"],
input[type="submit"] {
  background-color: transparent;
  border: solid transparent;
  border-radius: var(--size-five);
  margin: none;
  padding: var(--size-ten);
  font-family: 'Sen', sans-serif;
  cursor: pointer;
}

input[type="email"]::placeholder,
input[type="text"]::placeholder,
textarea::placeholder {
  color: var(--input-color);
}

input[type="email"]:hover,
input[type="text"]:hover,
textarea:hover {
  border-color: transparent;
  color: var(--input-color);
}

input[type="email"]:focus,
input[type="text"]:focus,
textarea:focus {
  outline: none;
  color: var(--input-color);
}

@media screen and (max-width: 600px) {
  textarea {
    font-size: calc(var(--size-ten) + 1vw);
  } 
}