.footerContainer {
  position: relative;
  z-index: 2500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: calc(calc(var(--size-twenty) + var(--size-five)) + 1vw);
  box-shadow: 0 0 5px black;
}

.copyRightContainer,
.linkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.linkContainer {
  width: 20%;
}

.copyRightContainer {
  width: fit-content;
  flex-wrap: nowrap;
}

.copyRightIcon {
  font-size: calc(var(--size-ten) + 0.5vw);
  margin: auto 0;
} 

.copyRightText {
  font-size: calc(var(--size-ten) + 0.5vw);
} 