.flashLight {
  position: fixed;
  bottom: calc(var(--size-twenty) + 1vw);
  right: calc(var(--size-twenty) + 1vw);
  height: calc(80px + 2vw);
  width: calc(80px + 2vw);
  border-radius: 50%;
  z-index: 2000;
}

.flashLight img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.flashLightMessageNone,
.flashLightMessageContainer {
  transition: var(--transition-one);
  box-sizing: border-box;
  cursor: default;
  position: absolute;
  top: -125%;
  right: 50%;
}

.flashLightMessageContainer {
  visibility: visible;
  opacity: 1;
}

.flashLightMessageNone { 
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.flashLightMessage {
  position: relative;
  border-radius: var(--size-five);
  width: calc(calc(var(--size-fifty) * 2) + 5vw);
  padding: var(--size-fifteen);
  border: var(--size-five) solid;
}

.triRight.bottomRightIn:before {
	content: ' ';
	position: absolute;
	right: var(--size-thirty);
	bottom: -1.5625rem;
	border: var(--size-twenty) solid;
	border-color: currentColor currentColor transparent transparent;
}