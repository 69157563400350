.loadInContainer,
.designBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadInContainer {
  z-index: 2000;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: container 10s forwards;
  font-family: 'Exo 2', sans-serif;
}

.designBox {
  position: relative;
  animation: animatedBox 10s forwards;
}

.logoContainer {
  width: calc(50px * 1vw);
}

.logoContainer {
  height: 100%;
  width: 100%;
}

.html,
.css,
.javaScript,
.typeScript,
.python,
.react,
.reactNative,
.nodeJS,
.express,
.mongoDB {
  opacity: 0;
  position: absolute;
  animation: 10s forwards;
  font-size: var(--size-forty);
}

.html { animation-name: html; }
.css { animation-name: css; }
.javaScript { animation-name: javaScript; }
.typeScript { animation-name: typeScript; }
.python { animation-name: python; }
.react { animation-name: react; }
.reactNative { animation-name: reactNative; }
.nodeJS { animation-name: nodeJS; }
.express { animation-name: express; }
.mongoDB { animation-name: mongoDB; }

.html,
.mongoDB {
  top: 0;
  left: var(--size-ten);
  transform: translateY(-50%);
}

.css,
.typeScript {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.javaScript,
.reactNative {
  top: 0;
  right: var(--size-ten);
  transform: translateY(-50%);
}

.react,
.python {
  bottom: 0;
  right: var(--size-ten);
  transform: translateY(50%);
}

.nodeJS {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.express {
  bottom: 0;
  left: var(--size-ten);
  transform: translateY(50%);
}

@keyframes animatedBox {
  0% {
    height: 0;
    width: 0;
    font-size: 0;
  }
  10% {
    height: 0;
    width: 0;
    font-size: var(--size-fifty);
    border: var(--size-two) solid;
  }
  20% {
    height: 40%;
    width: 60%;
  }
  85% {
    height: 40%;
    width: 60%;
    font-size: var(--size-fifty);
    border: var(--size-two) solid;
  }
  88% {
    border-top: none;
  }
  90% {
    font-size: 0;
  }
  91% {
    border-right: none;
  }
  94% {
    border-bottom: none;
  }
  97% {
    border-left: none;
  }
  98% {
    height: 0;
    width: 0;
  }
  100% {
    height: 0;
    width: 0;
    font-size: 0;
    border: none;
  }
}

@keyframes container {
  0% {
    height: 100%;
    width: 100%;
  }
  96% {
    height: 100%;
    width: 100%;
    opacity: 1;
  }
  100% {
    height: 0;
    width: 0;
    opacity: 0;
  }
}

@keyframes html {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  26% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
}

@keyframes css {
  0% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  32% {
    opacity: 1;
  }
  38% {
    opacity: 0;
  }
}

@keyframes javaScript {
  0% {
    opacity: 0;
  }
  32% {
    opacity: 0;
  }
  38% {
    opacity: 1;
  }
  44% {
    opacity: 0;
  }
}

@keyframes react {
  0% {
    opacity: 0;
  }
  38% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes nodeJS {
  0% {
    opacity: 0;
  }
  44% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  56% {
    opacity: 0;
  }
}

@keyframes express {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  56% {
    opacity: 1;
  }
  62% {
    opacity: 0;
  }
}

@keyframes mongoDB {
  0% {
    opacity: 0;
  }
  56% {
    opacity: 0;
  }
  62% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
}

@keyframes typeScript {
  0% {
    opacity: 0;
  }
  62% {
    opacity: 0;
  }
  68% {
    opacity: 1;
  }
  74% {
    opacity: 0;
  }
}

@keyframes reactNative {
  0% {
    opacity: 0;
  }
  68% {
    opacity: 0;
  }
  74% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}

@keyframes python {
  0% {
    opacity: 0;
  }
  74% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
}