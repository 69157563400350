.navBar {
  font-family: 'Exo 2', 'Barlow', sans-serif;
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  transition: var(--transition-two);
  box-shadow: 0 0 5px black;
  box-sizing: border-box;
  padding: var(--size-ten) calc(var(--size-forty) + 1vw);
  z-index: 1000;
}

.navBar .logoContainer,
.navBar .linkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBar a {
  margin-right: var(--size-twenty);
  font-size: calc(var(--size-ten) + 1vw);
}

.navBar .home,
.navBar .about,
.navBar .contact,
.navBar .logo {
  text-shadow: var(--text-shadow-one);
}

.navBar .contact {
  margin-right: 0;
}

.navBar .logo {
  font-size: calc(var(--size-twenty) + 1vw);
  transition: var(--transition-one);
}

.navBar .flipLogo {
  transform: rotate(360deg);
}

.navBar .hamburgerMenuContainer {
  display: none;
  transition: var(--transition-one);
}

.navBar .hamburger {
  font-size: calc(var(--size-twenty) + 1vw);
  transform: rotate(0deg);
  transition: var(--transition-one);
  cursor: pointer;
}

.navBar .hamburgerToggle {
  transform: rotate(360deg);
}

.navBar .display {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: calc(var(--size-fifty) * 1.25);
  padding: var(--size-twenty);
  border-top-left-radius: var(--size-ten);
  border-bottom-left-radius: var(--size-ten);
  animation: slideInRight 1s forwards;
}

.navBar .toggleOff {
  animation: slideOutRight 1s forwards;
}

.navBar .display,
.navBar .doNotDisplay,
.navBar .hamburgerMenuContainer {
  transition: var(--transition-two);
}

@keyframes slideInRight {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideOutRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}

@media screen and (max-width: 500px) {
  .navBar .doNotDisplay {
    display: none;
  }

  .navBar .hamburgerMenuContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: calc(var(--size-twenty) + 1vw);
    width: calc(var(--size-twenty) + 1vw);
  }

  .navBar .display .navOptions {
    margin: var(--size-five) 0;
  }

  .navBar .display a {
    font-size: calc(var(--size-fifteen) + 1vw);
  }
}