.removeParallax,
.parallaxContainer {
  font-family: 'Sen', san-serif;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.parallaxContainer {
  background-attachment: fixed;
}

.parallaxContent {
  padding-top: var(--size-fifty);
}

.removeParallax h1,
.removeParallax h2,
.removeParallax div,
.parallaxContainer h1,
.parallaxContainer h2,
.parallaxContainer div {
  text-shadow: var(--text-shadow-one);
  font-weight: 400;
}

.removeParallax h1,
.removeParallax h2,
.parallaxContainer h1,
.parallaxContainer h2 {
  font-size: calc(var(--size-ten) + 1vw);
}

.removeParallax div,
.parallaxContainer div {
  font-size: calc(var(--size-thirty) + 1vw);
}